<template>
  <Layout>
    <div class="AccountSetting">
      <page-header title="アカウント設定" :items="breadCrumbItems" />
      <div class="AccountSetting__content">
        <account-setting-form v-if="!isSystemUser" />
        <account-m-t-form v-else />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/layouts/main/app'
import PageHeader from '@/layouts/vertical/page-header'
import AccountSettingForm from '@/components/forms/account-setting-form'
import AccountMTForm from '@/components/forms/account-mt-form'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountSettingPage',
  components: { AccountSettingForm, AccountMTForm, PageHeader, Layout },
  data() {
    return {
      breadCrumbItems: [
        { text: '設定', href: '#' },
        { text: 'アカウント設定', href: '#', active: true },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['isSystemUser']),
  },
}
</script>
